<template>
  <div :class="['limit-content',item.isGet ? 'did-get' : 'did-not']" :style="contentStyle"
    v-for="(item,index) in canGetDisCoupons" :key="index">
    <div class="limit-left">
      <div class="limit-price">
        <div v-if="item.type!=='6'" class="">HK$<span>{{item.price}}</span></div>
        <div v-else><span>{{ item.discount }}</span>%OFF</div>
      </div>
      <div class="max-conditions">
        <div v-if="langEnv == '3'">
          {{ $t("滿") }} {{ item.metPrice }} {{ $t("可用") }}
        </div>
        <div v-else>
          {{ $t("可用") }} {{ $t("滿") }} {{ item.metPrice }}
        </div>
      </div>
    </div>
    <div class="limit-right">
      <div>
        <div class="title">
          <div class="left">
            {{ item.name }}
          </div>
        </div>
        <div class="bottom">
          <div class="left"><span>{{ item.date }}</span></div>
          <div class="right" v-if="!item.isGet" @click="nowOrder(item.id,1,'0')">
            {{ $t("領取") }}
          </div>
          <div class="right-get" v-else>{{ $t('已領取') }}</div>
        </div>
        <div class="question" v-if="isAll">
          <div v-if="item.isAll">{{ $t("適用於所有的產品") }}</div>
          <div v-else>{{ $t("只適用於指定產品") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTakeCanGetCoupon } from "@/api/coupon.js"
import { takeCoupons } from "@/api/newCustomers"
import { getToken } from "@/utils/token.js"
export default {
  name: "limit-coupon-layout",
  props: {
    list: {
      type: Array,
      default: []
    },
    contentStyle: {
      type: String,
      default: ""
    },
    isAll: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    list: {
      handler (val) {
        this.canGetDisCoupons = val
      },
      deep: true,
      immediate: true

    }
  },
  data () {
    return {
      langEnv: null,
      Tagtype: null,
      canGetDisCoupons: [],
    }
  },
  mounted () {
    this.langEnv = localStorage.getItem("langEnv") || 2
  },
  methods: {
    isLogin () {
      var token = getToken()
      if (!token) {
        var _this = this
        var func1 = function () {
          mmm.toLogin()
        }
        var func2 = function () {
          window.webkit.messageHandlers.toLogin.postMessage("jj")
        }
        var func3 = function () {
          _this.$router.push({
            path: "/login?redirect=" + _this.$route.fullPath
          })
        }

        this.excFunc(func1, func2, func3)
      } else {
        return true
      }
    },
    async nowOrder (id, flag, type) {
      if (type !== "0") {
        this.$toast("活动未开始，请稍后再来")
        return
      }
      if (this.isLogin()) {
        const res = await getTakeCanGetCoupon({ id: id }, this.langEnv)
        if (res.isSuccess) {
          this.$toast(this.$t("領取") + this.$t("成功"))
          this.$emit('success', id)
        }
      }
    },
  }
}
</script>
<style scoped lang="scss">
.limit-content {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 304px;
  min-height: 92px;
  position: relative;
  border-radius: 12px;
  height: 100%;
  margin-bottom: 12px;
  flex: 1;
}

.did-not {
  background: url("~@/assets/image/coupon/mycoupon.png") no-repeat;
  background-size: 100% 100%;
}

.did-get {
  background: url("~@/assets/image/coupon/efficacy.png") no-repeat;
  background-size: 100% 100%;
}

.limit-content img {
  width: 100%;
}

.limit-left {
  width: 30%;
  padding: 0 5px;
  box-sizing: border-box;
}

.limit-left .limit-price {
  font-size: 18px;
  color: #e84935;
  font-weight: 700;
  display: flex;
  align-items:baseline;
  justify-content: center;
  line-height: 1;
  margin-bottom: 4px;
  div{
    font-size: 12px;
    span{
      font-size: 18px;
    }
  };
}

.did-get .limit-left .limit-price {
  color: #000000;
}

.did-get .limit-left .max-conditions {
  color: #000000;
}

.limit-left .max-conditions {
  color: #e84935;
  font-size: 12px;
  div{
    text-align: center;
    // word-break: break-all;
  }
}

.limit-right {
  padding: 12px;
  flex: 1;
  font-size: 2vh;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.limit-right .title {
  overflow: hidden;
  min-height: 40px;
}

.limit-right .title .left {
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
}

.limit-right .bottom {
  display: flex;
  align-items: center;
  font-weight: 400;
  bottom: 0.6vh;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.limit-right .bottom .left {
  font-weight: 500;
  font-size: 11px;
  color: #D32D18;
  line-height: 16px;
  text-align: left;
  flex: 1;
}

.did-get .limit-right .bottom .left {
  color: #666666;
}

.limit-right .bottom .right {
  cursor: pointer;
  width: 55px;
  height: 22px;
  color: #fff;
  background: linear-gradient(270deg, #E84935 0%, rgba(232, 73, 53, 0.6) 100%);
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.limit-right .bottom .right-get {
  cursor: pointer;
  width: 55px;
  height: 22px;
  color: #fff;
  background: #BDBDBD;
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.no-inventory {
  background: linear-gradient(270deg, #bdbdbd 0%, #e0d3d3 100%) !important;
}

.maxDiscountPrice {
  font-weight: 500;
  font-size: 10px;
  color: #ef7b6c;
}

.question {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #999999;
  font-weight: normal;
}
</style>