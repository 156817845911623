<template>
  <div class="mask-box" v-show="isPrompt">
    <div class="context-box">
      <div class="dialog">
        <div class="title">
          <div>{{ $t("請輸入支付密码") }}</div>
          <img @click="hide" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
        <div class="text">
          {{ formatNumber(content) }}
        </div>
        <van-password-input :value="value" :focused="showKeyboard" @focus="showKeyboard = true" />
        <div class="pwd-btn" @click="handleNext">{{$t('忘記支付密碼')}}？</div>
      </div>
    </div>
     <!-- 数字键盘 -->
    <van-number-keyboard :show="showKeyboard" @input="onInput" @delete="onDelete" @blur="showKeyboard = false" />
  </div>

</template>
<script>
import mixins from "@/utils/mixins.js"
export default {
  name: "NumberPassWordDialog",
  mixins: [mixins],
  props: {
    content: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      value: "",
      showKeyboard:false,
      isPrompt: false,
    }
  },
  methods: {
    open (data) {
      this.isPrompt = true
    },
    hide () {
      this.isPrompt = false
      this.value=""
    },
    onInput(key){
      this.value = (this.value + key).slice(0, 6);
      if(this.value.length===6){
        this.$emit('success',this.value)
        this.hide()
      }
    },
    onDelete(){
      this.value = this.value.slice(0, this.value.length - 1);
    },
    handleNext(){
      this.$router.push("/setpPayPass")
    },
  }
}
</script>

<style lang="scss" scoped>
.mask-box{
  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color:rgba(0,0,0,0.7);
  z-index:2025;
  .context-box{
    width:100%;
    height:100%;
    box-sizing: border-box;
    padding: 0 20px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
}
.dialog {
  width:100%;
  box-sizing: border-box;
  padding: 24px;
  background:#ffffff;
  border-radius: 10px;
  position: relative;
  .text {
    font-weight: bold;
    font-size: 28px;
    color: #1A1A1A;
    line-height: 24px;
    text-align: center;
    margin-top:16px;
    margin-bottom:32px;
  }


  .title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 500;

    div {
      font-weight: 500;
      font-size: 17px;
      color: rgba(0,0,0,0.9);
      line-height: 24px;
    }

    img {
      width: 24px;
      position: absolute;
      right: 16px;
      top: 24px;
    }
  }
  .pwd-btn{
    margin-top:12px;
    font-weight: 400;
    font-size: 14px;
    color: #3491FA;
    line-height: 22px;
    text-align: right;
  }
}
</style>