import config from "./config"

export default {
  data () {
    return {
      BASE_IMG: config.imgUrl,
      defaultImg: require('@/assets/image/e-Rith.png'),
    }
  },
  methods: {
    numberToTwoDecimals(num) {
      let str = num.toString();
      if (!str.includes('.')) {
        return str + '.00';
      }
      let parts = str.split('.');
      return parts[0] + '.' + (parts[1].length >= 2 ? parts[1] : parts[1] + '0');
    },
    toFixedFun (data, len) {
      let val = data
      if (data < 0) {
        val = -data
      }
      return Math.floor(val * len) / len
    },
    handleChangeLang (val, localeData) {
      if (val == 2) {
        localeData.global.locale.value = "en"
      } else if (val == 3) {
        localeData.global.locale.value = "zh"
      }
    },
    isMobileTerminal () {
      var u = navigator.userAgent
      const isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1 //android终端
      const isIos =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1 //ios终端
      return isAndroid || isIos
    },
    orderStatus (type = 0, v) {
      switch (type) {
        case 0:
          if (v.expiredHour <= 0 && v.expiredMinutes <= 0) {
            return "已關閉"
          } else {
            return "待付款"
          }
        case 1:
          return "支付中"
        case 2:
          return "待發貨"
        case 3:
          return "待收貨"
        case 4:
          return "已完成"
        case 5:
          return "已關閉"
        case 6:
          return "預購中"
        case 8:
          return "待發貨"
        case 9:
          return "待審核"
        default:
          return null
      }
    },
    getAfterSalesStatus (status) {
      switch (status) {
        case 0:
          return "待審核";
        case 1:
          return "待寄回";
        case 2:
          return "商家待收貨";
        case 3:
          return "退款中";
        case 4:
          return "已完成";
        case 5:
          return "關閉";
        case 6:
          return "已拒絕";
        default:
          return "";
      }
    },
    formatNumber (str) {
      if (Number(str) === 1000000) {
        return "詢價"
      }
      return (Number(str) || 0).toLocaleString("en-US", {
        style: "currency", // 样式为货币格式
        currency: "HKD", // 货币类型为港币 (Hong Kong Dollar)
        minimumFractionDigits: 2, // 最小小数位数为2，如果小数位数不足则补0
        maximumFractionDigits: 2 // 最大小数位数为2，如果小数位数多余则进行四舍五入
      })
    },
    getProductImageUrl (picturePath, size, index) {
      if (index) {
        if (index !== 0 && picturePath.includes("_kyj")) {
          picturePath = picturePath ? picturePath.replace("_kyj", "") : ""
        }
      }
      picturePath = picturePath
        ? picturePath.replace("{0}", `${size}x${size}`)
        : ""
      return `${config.imgUrl}${picturePath}`
    },
    // 打开app
    openApp () {
      var u = navigator.userAgent
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isAndroid) {
        window.location.href = window.config.Android.h5Index
        window.setTimeout(function () {
          window.location.href = window.config.Android.appUrl
        }, 500)
      }
      if (isiOS) {
        window.location.href = window.config.IOS.h5Index
        setTimeout(function () {
          window.location.href = window.config.IOS.appUrl //ios下载地址
        }, 250)
        setTimeout(function () {
          window.location.reload()
        }, 300)
      }
    },

    excFunc (func1, func2, func3) {
      var u = navigator.userAgent
      var isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1 //android终端
      var isiOS =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1 //ios终端
      if (isAndroid) {
        func1 && func1()
      } else if (isiOS) {
        func2 && func2()
      } else {
        func3 && func3()
      }
    },
    excFunc1 (func1, func2, func3, func4) {
      var u = navigator.userAgent
      let ua = navigator.userAgent.toLowerCase()
      var isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1 //android终端
      var isiOS =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1 //ios终端
      // 如果是Android设备，则执行func1函数（如果func1是一个函数的话）。
      if (isAndroid) {
        func1 && func1()
        // 如果是iOS设备，则执行func2函数（如果func2是一个函数的话）。
      } else if (isiOS) {
        func2 && func2()
        // 如果是微信浏览器，则进一步判断是否是小程序环境，通过正则表达式匹配miniProgram来进行判断。如果是小程序环境，则执行func4函数（如果func4是一个函数的话），否则执行func3函数（如果func3是一个函数的话）。
      } else if (ua.match(/MicroMessenger/i) == "micromessenger") {
        if (ua.match(/miniProgram/i) == "miniprogram") {
          func4 && func4()
        } else {
          func3 && func3()
        }
      } else {
        func3 && func3()
      }
    },
    // 这段代码是一个用来检查是否在微信浏览器中运行的函数
    isWeiXin () {
      let ua = window.navigator.userAgent.toLowerCase()
      let ub = window.navigator.userAgent
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" ||
        ub.indexOf("MMMMessenger") > -1 ||
        window.__wxjs_environment === "miniprogram"
      ) {
        return true
      } else {
        return false
      }
      /* if (ua.match(/MicroMessenger/i) == 'micromessenger' || ub.indexOf('MMMMessenger') > -1) {
         return true;
       } else {
         return false;
       }*/
    },
    // 这段代码是一个名为isMobile()的函数，用于检测当前设备是否为移动设备。下面是对代码的解释和步骤：
    isMobile () {
      let ub = window.navigator.userAgent
      if (
        ub.indexOf("MMMMessenger") > -1 ||
        window.__wxjs_environment === "miniprogram"
      ) {
        return true
      } else {
        return false
      }
    },

    // 获取url中的参数
    getUrlParams () {
      let url = decodeURI(location.search)
      let urlParams = new Object()
      if (url.indexOf("?") != -1) {
        let str = url.substr(1).split("&")
        for (let i = 0; i < str.length; i++) {
          urlParams[str[i].split("=")[0]] = unescape(str[i].split("=")[1])
        }
      }
      return urlParams
    },
    // 防抖
    debounce (fn, duration = 500) {
      let timer
      return function () {
        let args = arguments
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          fn.apply(this, args)
        }, duration)
      }
    },
    /**
     * 节流
     * @param {Function} fn 执行的函数
     * @param {Number} duration 间隔时间 ms
     */
    throttle (fn, duration = 500) {
      let timer = null
      return function () {
        if (timer) return
        const args = arguments
        timer = setTimeout(() => {
          fn.apply(this, args)
          timer = null
        }, duration)
      }
    }
    // formatNumber(num) {
    //   let precision = 2,
    //     parts,
    //     separator;
    //   // 判断是否为数字
    //   if (!isNaN(parseFloat(num)) && isFinite(num)) {
    //     num = Number(num);
    //     // 处理小数点位数
    //     num = (typeof precision !== "undefined"
    //       ? num.toFixed(precision)
    //       : num
    //     ).toString();
    //     // 分离数字的小数部分和整数部分
    //     parts = num.split(".");
    //     parts[0] = parts[0]
    //       .toString()
    //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + (separator || ","));
    //     return "HK$" + parts.join(".");
    //   }
    //   return NaN;
    // }
  },
  handleImageError (e) {
    e.target.src = this.defaultImg // 只需要将此路径换成对应的默认图片路径即可
  },
}
