export default [
  {
    path: '/order/confirm2',
    name: 'OrderConfirm2',
    component: () => import('@/views/order/index.vue'),

  },
  {
    path: '/user/orderList',
    name: 'OrderList',
    component: () => import('@/views/order/orderList.vue'),

  },
  {
    path: '/user/afterSales',
    name: 'afterSales',
    component: () => import('@/views/afterSales/index.vue'),
  },
  {
    path: '/user/afterSalesDetail/:orderNo',
    name: 'afterSalesDetail',
    component: () => import('@/views/afterSales/afterSalesDetail.vue'),
  },
  {
    path: '/user/selectGoods/:orderNo',
    name: 'selectGoods',
    meta: { title: "选择退货商品", hideTab: true },
    component: () => import('@/views/order/selectReturnGoods.vue'),
  },
  {
    path: '/user/returnGoodsDetil/:orderNo',
    name: 'returnGoodsDetil',
    meta: { title: "訂單詳情", hideTab: true },
    component: () => import('@/views/order/returnGoodsDetil.vue'),
  }

]